
import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyA6Z_TdrhFuTNkDhPH0FwtBybWGiFWBV2Y",
  authDomain: "midterm-135a0.firebaseapp.com",
  projectId: "midterm-135a0",
  storageBucket: "midterm-135a0.appspot.com",
  messagingSenderId: "488512951856",
  appId: "1:488512951856:web:391d3ef32683bdc52afff5",
  measurementId: "G-GHRL1HDG03"
};

firebase.initializeApp(firebaseConfig);

export default firebase;